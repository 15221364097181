import {
  DeleteFilled,
} from '@ant-design/icons';
import 'assets/styles/datatable.css';
import { Popconfirm, Space } from 'antd';
import { checkDataEntryRole } from 'components/RequireDataEntry';
import { useAuth } from 'contexts/AuthContext';
import { useLocalization } from 'contexts/LocalizationContext';

// using the same check states to say that the button has been clicked
function TransformationDeleteButton(props) {
  const { user } = useAuth();
  const loc = useLocalization();
  let operationType = null;
  const closeMonthArray = [
    'Saisie terminée',
    'Close data entering for this month',
    'Invoer voltooid',
  ];

  const {
    deleteRow,
    editorLostFocus,
    editorOnFocus,
    transId,
    typeRow,
    row,
  } = props;

  function handleDeleteRow() {
    deleteRow(transId);
  }

  if (checkDataEntryRole(user) === false) {
    return <Space />;
  }

  if (row !== undefined) {
    operationType = row.type;
  }

  if ((closeMonthArray.includes(operationType) && !user.roles.includes('ROLE_FED_ADMIN')) || (closeMonthArray.includes(typeRow) && !user.roles.includes('ROLE_FED_ADMIN'))) {
    return <Space />;
  }

  return (
    <Popconfirm onConfirm={handleDeleteRow} title={loc.locObject.translate('transformation_delete_button_messege')} autoAdjustOverflow={{ maxWidth: 300 }}>
      <div className="dt-table-delete-button-container d-100 h-100">
        <DeleteFilled
          onFocus={editorOnFocus}
          onBlur={editorLostFocus}
        />
      </div>
    </Popconfirm>
  );
}
export default TransformationDeleteButton;
